import React from 'react';
import {NavLink} from 'react-router-dom'

import Holder from './../../../../themes/nerdify/components/Holder';
import TextPage from './../TextPage';
import LocalesPage from './../LocalesPage';

import { REACT_APP_PP_EMAIL } from '../../../../config';

export default (props) => (
  <LocalesPage {...props}>
    <TextPage>
    <Holder className="holder_1">
      <h1 className="h1">Privacy Policy</h1>

      <div className="textPage-item">
        <h2 className="h3">1. Introduction</h2>
        <p className="p">Hello there! This section will help you better understand how Nerdify collects, stores, processes and uses your personal information. Since privacy laws are constantly changing, the Privacy Policy section can be occasionally updated. The last update of the current version of our Privacy Policy was made on February 14, 2022, and will apply to any use of the Nerdify website, mobile application and services starting February 14, 2022, and onwards. At the same time, this Privacy Policy does not apply to any personal data that you share with the third parties.</p>
      </div>

      <div className="textPage-item">
        <h2 className="h3">2. Collection of Information</h2>
        <p className="p">At Nerdify, we collect such personal information as your phone number because this data is crucial for providing our services to you. In case you prefer not to share this information with us, you can still check our website but you will not be able to get access to these services.</p>
        <p className="p">The data we collect, store, process, and use can be divided into three subtypes: the data you decide to provide to us directly, the information we collect automatically, and the information we receive from other sources.</p>

        <div className="textPage-item">
        <h3 className="h5">2.1. Information you provide directly</h3>
        <p className="p">When you fill in the form or contact us directly through any chosen way of communication, you may specify personal details, for example, your email address, phone number, level of education, photos and any other information you decide to share with us.</p>
        </div>

        <div className="textPage-item">
        <h3 className="h5">2.2. Information we collect automatically</h3>
        <p className="p">When you use Nerdify website or Nerdify mobile application, the following information is collected automatically:</p>
        <ul className='ul'>
          <li>Data about your desktop or mobile device you use to access the website/application, such as device hardware model, operating system version, and screen resolution;</li>
          <li>Log information that includes your IP address, browser version, the configuration of the app, time, date, duration, and frequency of your visits to the website/application use as well as pages you accessed during them. The application may request access to data that is only relevant for the core functionality of the app and only collects and uses data that is required to process the specific help request.</li>
        </ul>
        <p className="p">The above-mentioned information is gathered with the help of cookies, pixels, and other tracking devices. Learn more about the types of cookies we use in our <NavLink className='a' to='/cookies'>Cookie Policy</NavLink>.</p>
        </div>

        <div className="textPage-item">
        <h3 className="h5">2.3. Information we obtain from other sources</h3>
        <p className="p">We may collect information about you from the third-party services such as social media websites only in case you gave those third-party services your consent to share this information with us. It is vital for providing our free and paid services to you.</p>
        </div>

        <div className="textPage-item">
        <h3 className="h5">2.4. Sensitive information</h3>
        <p className="p">We do not gather any sensitive information about you. We strongly encourage you not to provide this data to us. Sensitive information can include your race, ethnicity, gender, health condition, philosophical and religious beliefs, political views, and the like. If you decide to give this information to other website users, Nerdify does not take any responsibility for it.</p>
        </div>

        <div className="textPage-item">
        <h3 className="h5">2.5. Children online privacy protection</h3>
        <p className="p">Nerdify is not meant for usage by individuals under eighteen (18) years old. We will not intentionally gather, save and use information about such individuals.</p>
        </div>
      </div>

      <div className="textPage-item">
        <h2 className="h3">3. Use of information</h2>
        <p className="p">The information you share with us is used in order to improve, operate and protect Nerdify as well as to communicate with you and give you access to our paid and free services.</p>

        <div className="textPage-item">
        <h3 className="h5">3.1. Using your data for Nerdify website/application operation</h3>
        <p className="p">Improving Nerdify implies the following:</p>
        <ul className='ul'>
          <li>Increase of the website or mobile application performance;</li>
          <li>Processing transactions;</li>
          <li>Development of the new products and services;</li>
          <li>Monitoring and analysis of your activity to create the best experience for all website users;</li>
          <li>Detection, prevention, and investigation of the fraudulent cases;</li>
          <li>Prevention of the website abuse and activities that are illegal or violate our Terms of Use, Privacy Policy, and other policies;</li>
          <li>Protection of the rights and safety of all website users;</li>
          <li>Conduction of the statistical analysis and performance of other administrative tasks.</li>
        </ul>
        </div>

        <div className="textPage-item">
        <h3 className="h5">3.2. Using your data for communicating with you</h3>
        <p className="p">Your personal data is needed in order to:</p>
        <ul className='ul'>
          <li>Provide customer service and technical support;</li>
          <li>Answer your questions;</li>
          <li>Update you with the recent news or alerts;</li>
          <li>Organize surveys.</li>
        </ul>
        </div>

        <div className="textPage-item">
        <h3 className="h5">3.3. Advertising and other uses</h3>
        <p className="p">Your personal information can also be used for advertising purposes, such as:</p>
        <ul className='ul'>
          <li>Providing you with the personalized content and website features;</li>
          <li>Promoting the content that may be applicable to you;</li>
          <li>Providing personalized advertising through third-party platforms.</li>
        </ul>
        </div>

        <div className="textPage-item">
        <h3 className="h5">3.4. Legal basis for processing your information</h3>
        <p className="p">Your personal information is gathered and used legally. The legal basis for it depends on the context in which we receive your data.</p>
        <ul className='ul'>
          <li>The legal basis for storing your phone number is that this information is inevitable for providing you with the free and paid website features;</li>
          <li>Collection and processing information about you might be needed in order to comply with the legal requirements such as fraud prevention, network improvement and database safety, to protect your interests and interests and privacy of other website users.</li>
          <li>Gathering your personal information is necessary for improving your experience with the website or mobile application.</li>
        </ul>
        </div>
      </div>

      <div className="textPage-item">
        <h2 className="h3">4. Sharing information and Affiliate Disclosure</h2>
        <p className="p">Information about you can be shared with our third-party service providers and authorities in order to comply with the legal obligations and to protect our legal rights and property.</p>
        <p className="p">Data obtained through the short code program will not be shared with any third-parties for their marketing reasons/purposes.</p>

        <div className="textPage-item">
          <h3 className="h5">4.1. Sharing information with the service providers</h3>
          <p className="p">Third-party services are used for website and mobile application improvement and effective performance. These services help us operate the payments, send emails and text messages, host our data, show ads across the Internet, and give technical support. We give these services the right to use your personal information for their intended purposes, however, we make sure that this usage does not go beyond our authorization.</p>
        </div>

        <div className="textPage-item">
          <h3 className="h5">4.2. Transfer of information to other countries</h3>
          <p className="p">Your personal information may be transferred to other countries as these countries may have data protection regulation laws that vary from your home country. Data protection measures that apply to such transfers have been taken to provide the correspondence with the requirements of this Privacy Policy.</p>
        </div>

        <div className="textPage-item">
          <h3 className="h5">4.3. Sharing information for security reasons</h3>
          <p className="p">Your personal information is stored securely and it is protected against illegal usage. Our employees are trained in correspondence with this and other data protection policies. Only authorized individuals have access to use your data. Moreover, we constantly monitor our hardware and software for potential vulnerabilities.</p>
        </div>

        <div className="textPage-item">
          <h3 className="h5">4.4. Affiliate Disclosure</h3>
          <p className="p">Some links in our email campaign may be affiliate links. Affiliate links are developed to provide a means for other sites to earn advertising fees. The final cost will be the same whether you use the affiliate link. But as a member of an affiliate program, nerdifyit.com will earn a commission if you purchase any service through an affiliate link.</p>
        </div>


        <div className="textPage-item">
          <h3 className="h5">4.5. Third-party Partners and Privacy</h3>
          <p className="p">
            In order to display ads online, to get information regarding the Service usage and the effectiveness of our advertisements and promotions, Nerdify collaborates with third-party partners such as online advertising platforms and analytics providers. These third-parties might implement and use their own tracking tools, such as cookies and web tags, on your devices, as well as to gather or have access to Device ID/Information and other data about you, some of which might qualify as personal data according to specific state regulations. Some of these third-parties can accumulate personal data over time during your engagement with the Service or other online platforms. We might disclose information to third-party partners, typically in an aggregated, depersonalized, or pseudonymized form, along with Device ID/Information and location details, with the intention of presenting you with more relevant advertisements both on and off Service.
          </p>

          <p className="p">
            If you would like to opt-out of third-party advertising platforms and analytics providers, you have several
            options for that:
            <ul className='ul'>
              <li>There are technical specification in your browser settings that you can use to automatically inform
                websites of your privacy preferences. Learn more about and set up <a href="https://globalprivacycontrol.org/#about" className="a" target='_blank' rel='noopener noreferrer'>Global Privacy Controls</a> here.</li>
              <li>We use Google Analytics, which use cookies and similar technologies to collect and analyze information
              about the use of the Services. Learn about <a href="https://policies.google.com/technologies/partner-sites" className="a" target='_blank' rel='noopener noreferrer'>Google's practices</a>, and opt out of them by downloading the <a
                  href="https://tools.google.com/dlpage/gaoptout" className="a" target='_blank' rel='noopener noreferrer'>Google Analytics opt-out browser add-on</a>.</li>
              <li>There are settings that request that a web application (web browser) disable its tracking of an
                individual user. Learn more about and set up <a href="https://allaboutdnt.com/" className="a" target='_blank' rel='noopener noreferrer'>Do Not Track here.</a></li>
              <li>There are mobile device settings that allow you to disable tracking advertising and/or analytics. Also
              there is the ability for mobile users to register at <a href="https://youradchoices.com/appchoices" className="a" target='_blank' rel='noopener noreferrer'>AppChoices</a> for their intent not to receive targeted
                advertising.</li>
            </ul>
          </p>

          <p className="p">Kindly be aware that utilizing these options to opt out does not exempt you from receiving advertisements. You will still receive generic ads during your online activities.</p>
        </div>
      </div>

      <div className="textPage-item">
        <h2 className="h3">5. Your choices and data rights</h2>
        <p className="p">Your data rights consist of:</p>
        <ul className='ul'>
          <li>The right to check, modify and request to delete your information. You can do it by contacting us at <a
            className='a' href={'mailto:' + REACT_APP_PP_EMAIL} target='_blank'
            rel='noopener noreferrer'>{REACT_APP_PP_EMAIL}</a>.
          </li>
          <li>The right to restrict the processing of your data. If you want to be excluded from the segmentation for communication and other purposes, please, message us at <a className='a' href={'mailto:' + REACT_APP_PP_EMAIL} target='_blank' rel='noopener noreferrer'>{REACT_APP_PP_EMAIL}</a></li>
          <li>The right to transfer your information. If you want to review all the information we have about you, contact us at <a className='a' href={'mailto:' + REACT_APP_PP_EMAIL} target='_blank' rel='noopener noreferrer'>{REACT_APP_PP_EMAIL}</a> and we will send you a data file.</li>
          <li>The right to unsubscribe from marketing notifications. You can do that by clicking the unsubscribe link in emails you receive from us or by messaging us directly to <a className='a' href={'mailto:' + REACT_APP_PP_EMAIL} target='_blank' rel='noopener noreferrer'>{REACT_APP_PP_EMAIL}</a>.</li>
          <li>The right to withdraw your permission to process your personal information at any time. You can do it by contacting us at <a className='a' href={'mailto:' + REACT_APP_PP_EMAIL} target='_blank' rel='noopener noreferrer'>{REACT_APP_PP_EMAIL}</a>.</li>
          <li>The right to complain to your local Data Protection Authority (DPA) about our usage of your data.</li>
          <li>The right to opt out of our cookies usage and other tracking beams. Consult our <NavLink className='a' to='/cookies'>Cookie Policy</NavLink> to find out how you can restrict our usage of cookies.</li>
        </ul>
      </div>
    </Holder>
  </TextPage>
  </LocalesPage>
)
