import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
//import { Link } from 'react-router-dom';
import { Section6, Section15, SectionBlock } from '../../../../themes/nerdify/components/Section';
import Holder from './../../../../themes/nerdify/components/Holder';
import { Nerds2 } from '../../../../themes/nerdify/components/Nerds';
import { TextBack8 } from '../../blocks/TextBack';
import { Intro3 } from '../../../../themes/nerdify/components/Intro';
import { Hero7 } from '../../../../themes/nerdify/components/Hero';
import { Header1, HeaderIcon } from '../../../../themes/nerdify/components/Header/index';
import { Footer2 } from '../../blocks/Footer';
import { HnavMob1 } from '../../blocks/HnavMob';
import { Hnav3 } from "../../blocks/Hnav";

//import { InputSelectLocales } from './../../../../themes/nerdify/components/Form/InputSelect';
import LocalesPage from './../LocalesPage';
import { TopNerdsInfo3 } from "../../../../themes/nerdify/components/TopNerdsInfo";
import DemoSubjects from "../../../../themes/nerdify/components/DemoSubjects";
import {useMobile} from "../../../../hooks";
import * as demoCardsRecords from '../../blocks/DemoCards';
import DemoCards from "../../blocks/DemoCards";

const WithHook = (props) => {
  const isMobile = useMobile();

  return props.children({
    isMobile
  });
};

export default class AboutUs extends Component {
  render() {
    const { pageCfg } = this.props;
    return (
      <WithHook>
        {({
            isMobile
          }) => {

    return (
      <LocalesPage {...this.props}>
      <div className="about-us">
        <Header1>
          <Holder>
            <NavLink className='logo-link' to='/'>
              <HeaderIcon iconName='logo-light' theme='light'/>
            </NavLink>
            {isMobile ? <HnavMob1 /> : <Hnav3 theme='light' />}
{/*
            <div className="header-wrap">
              <NavLink className="button button__type9 hero__go-home" to='/'>Go to Home page</NavLink>
              <HnavMob1 />
              <InputSelectLocales
                className='input-select__short'
                name='country'
                isError={false}
              />
            </div>
*/}
          </Holder>
        </Header1>

        <Hero7 key='hero5'>
          <Holder className={'holder_3'}>
            <h1 className="hero__title">Nerdify is<br className="mobile-only"/> designed for people aiming to live a balanced life</h1>
          </Holder>
        </Hero7>

          <Intro3 indent={'pt40m pb60m pt60d pb30d'}>
            <Holder className={'holder_3'}>
              <p className="h6">Nerdify is a platform that provides access to 1000s of experienced Nerds who can help with on-demand requests.</p>
              <p className="h6">It was founded in September 2015 by 2 LSE graduates — Alex and Brian. One day they decided to create a simple service enabling them to connect to graduates with deep expertise in various fields. It all started as a tiny university project for their friends and classmates, but soon it went viral. Nerdify grew into one of the most popular AI-powered platforms providing outstanding support with routine requests like data research, data analysis, and more. Now, our Nerds help tens of thousands of students, businesses and academia all over the world!</p>
            </Holder>
          </Intro3>

          <Section6>
            <Holder className={'holder_1'} indent={'pt30m pb30m pt40d pb40d'}>
              <h2 className='h3'>Our mission</h2>
              <p className='p'>
                We aim to help students, businesses and academia focus on the most important things:<br className="desktop-only"/> getting a degree, achieving success and pursuing their career goals rather than wasting time on<br className="desktop-only"/> routine tasks. We want Nerdify to become your time-saving and energy-freeing sidekick.
              </p>
            </Holder>
          </Section6>

        <Section15>
          <Holder>
            <TopNerdsInfo3 titleTag={'h2'}/>

            <DemoSubjects />

            <Nerds2 indent={'pt50 pb40'}>
              <div className='nerds__title'>...from some of the <span className="strong">TOP universities</span></div>
            </Nerds2>
          </Holder>
        </Section15>


          <Holder>
            <hr className="hr" />
          </Holder>

{/*
          <Testimonials>
            <h3 className="h3">What do our clients say about
              their Nerdify experience?</h3>

            <TestimonialsList
              items={[
                {
                  image: 'image1',
                  name: 'John',
                  location: 'clinton, US',
                  title: 'Amazing service!',
                  text: 'Pure magic, nothing to add. They will always help when you need them. Thanks to Nerdify I have some time to sleep!',
                },
                {
                  image: 'image2',
                  name: 'Alex',
                  location: 'san francisco, US',
                  title: 'Very personalised touch',
                  text: 'Its really reassuring to find a company that can still preserve a very personalised touch of their services. And the reply time is like just 2 minutes. How do they do that?',
                },
                {
                  image: 'image3',
                  name: 'Jane Coyle',
                  location: 'omaha, US',
                  title: 'Simple, fast and reliable!',
                  text: 'These guys are always helpful! And it\'s super-convenient to get in touch with them - simple texting. What I like more is that they always understand everything well and it takes me seconds to explain everything. And they\'re really nice nerdifriends.',
                },
                {
                  image: 'image4',
                  name: 'Katie',
                  location: 'la, US',
                  title: 'Easy, quick and professional service',
                  text: 'I love that I always can text them and there will be a real person answering in a few minutes. It is like talking to a friend, who is smart and willing to help. Thank you nerds, you saved me many times!',
                },
              ]} />

          </Testimonials>
*/}
        <DemoCards
          indent={"pt40m pt60d"}
          titleTag={'h2'}
          records={ pageCfg.demoCards ? demoCardsRecords[pageCfg.demoCards] : '' }
        />
{/*
          <SectionBlock theme={3} indent={"pt40 pb40"}>
            <Holder className="holder_2">
              <div className="h1">Still doubting whether you need a Nerd?</div>
              <h5 className="h5">Message us to learn more about what Nerds can help with!</h5>
              <TextBack2 />
            </Holder>
          </SectionBlock>
*/}
        <SectionBlock theme={2} indent="pb80m pb70d">
          <Holder className="holder_3">
            <div className='section-title'>…or send your personal help request<br className="desktop-only"/> and we'll text you<br
              className="mobile-only"/> back in 10 sec</div>
            <TextBack8 {...this.props} submitBtnTxt={'Text me now'} />
          </Holder>
        </SectionBlock>

        <Footer2 />
      </div>
      </LocalesPage>
    )
        }}
      </WithHook>
    )
  }
}
