import React, { Fragment, useState, useEffect } from 'react';
import {Link, NavLink, useLocation} from 'react-router-dom';
import Holder from '../../../../themes/nerdify/components/Holder';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import {
  Header5,
  HeaderIcon,
} from './../../../../themes/nerdify/components/Header';
import { TextBack7 } from '../../blocks/TextBack'
import { Info5, Info6 } from './../../../../themes/nerdify/components/Info';
import DemoCards, * as demoCardsRecords from '../../blocks/DemoCards';
import { Hero13 } from '../../../../themes/nerdify/components/Hero';
import BackLink from '../../../../themes/nerdify/components/BackLink';
import { Footer2 } from '../../blocks/Footer';
import {
  Faq2,
  Accordion,
  AccordionHead,
  AccordionBody,
  AccordionItem,
  AccordionIcon,
  AccordionItemHolder,
} from '../../../../themes/nerdify/components/Faq';

import LocalesPage from '../LocalesPage';
import classnames from 'classnames';
import Button from '../../../../base_components/Button';
import { Form } from '../../../../themes/nerdify/components/Form';
import InputError from '../../../../themes/nerdify/components/Form/InputError';

import { updateUser, checkLeadExists } from '../../../../redux/actions';

import regexps from '../../../../utils/regexps';
import { parseQuery } from '../../../../utils';
import Text from "../../../../base_components/Text";

const ThankYou = (props) => {
  const { pageCfg } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  const { isPatching } = useSelector((state) => state.textback);

  const [email, setEmail] = useState('');
  //const [userId, setUserId ] = useState('')
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  let urlParams  = parseQuery(location.search);
  if ('amp_params' in urlParams) {
    let decodedAmpParams = Buffer.from(urlParams['amp_params'], 'base64').toString();
    urlParams  = parseQuery(decodedAmpParams);
  }

  const phone  = props.location.state && props.location.state.phone || _.get(urlParams, 'to', '').split('-').join('') || '';
  const landing_type  = props.location.state && props.location.state.landing_type || _.get(urlParams, 'landing_type', '') || '';
  //const source_url = props.location.state && props.location.state.source_url && decodeURIComponent(props.location.state.source_url) || decodeURIComponent(_.get(urlParams, 'source_url', '')) || '';

  let source_url = '';
  try {
    source_url = decodeURIComponent(props.location.state?.source_url ?? '');
  } catch (error) {
    console.error('Error decoding source_url from state:', error);
  }

  if (!source_url) {
    try {
      source_url = decodeURIComponent(_.get(urlParams, 'source_url', ''));
    } catch (error) {
      console.error('Error decoding source_url from URL params:', error);
    }
  }

  source_url = source_url || '';


  useEffect(() => {
    localStorage.removeItem('leadCreated');
  }, []);

  const validate = () => {
    const errors = {
      email: !regexps.email(email.trim()) ? 'Required field' : null,
    };

    const isError = Object.keys(errors).some((item) => errors[item]);

    setErrors(errors);

    return isError;
  };

  const getUserIdHelper = async () => {
    let phone;
    if (typeof window !== 'undefined') {
      phone = _.get(parseQuery(window.location.search), 'to', '');
    }

    if (phone) {
      const existsData = await dispatch(checkLeadExists(phone.split('-').join(''))).then(
        (resp) => resp.data
      );
      return existsData._id
    } else {
      return _.get(location, 'state.userId', '');
    }
  };

  const onSubmit = () => {
    const isError = validate();

    if (isError) {
      return false;
    }

    setErrors('');

    const userIdRes = getUserIdHelper();

    userIdRes.then(userId => {
      if (!userId) {
        return false;
      }

      setIsSubmitted(true);

      dispatch(updateUser(userId, email))
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          setErrors({
            server: error.text,
          });
        });
    })
  };

  const onChange = (e) => {
    const { value } = e.target;

    setEmail(value);
  };

  const isErrors = errors.server || errors.email;

  return (
    <LocalesPage {...props}>
      <div className="landing">
        <Header5>
          <Holder>
            <NavLink className="logo-link logo-link_1" to="/">
              <HeaderIcon iconName="logo-dark" theme={1} />
            </NavLink>
            {pageCfg.backLink && <BackLink path={source_url} phone={phone} />}
          </Holder>
        </Header5>

        <Hero13 subTheme={1}>
          <div className="hero-main">
            <div className="holder">
              <h1 className="hero__title">
                {landing_type === 'second' || landing_type === 'undelivered' ?
                  (
                    <>Done! We’ve just texted you again!</>
                  )
                  : (
                    <>We'll text you<br className="mobile-only" /> in a few seconds!</>
                  )
                }
              </h1>
              <h2 className="hero__subtitle top">
                {landing_type === 'second' || landing_type === 'undelivered' ?
                  (
                    <>Please check your phone number if you still don't receive our message.</>
                  )
                  : (
                    <>If you don't receive our message,<br/>
                      click the button below and we'll text you again.</>
                  )
                }
              </h2>


              <TextBack7 {...props} subTheme={6} submitBtnTxt={'Text me now'} />
              {landing_type === 'undelivered' && (
                <div className="hero__warning">If you put in the correct phone number and still don't get a message from Nerdify, <br className="desktop-only"/>
                contact us via <a className="hero__link" href="mailto:done@nerdifyit.com">done@nerdifyit.com</a></div>
              )}

              {!isSubmitted ? (
                <>
                  <h2 className="hero__subtitle bottom">
                    Enter your email to receive Nerdify discounts and promos
                  </h2>
                  <Form className={'email-form'}>
                    <div className="input-wrapper">
                      <input
                        className={classnames('input', {
                          input__error: isErrors,
                        })}
                        type="email"
                        name="email"
                        value={email}
                        placeholder="Email"
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="email"
                        onChange={onChange}
                      />
                      {isErrors && (
                        <InputError>{errors.email || errors.server}</InputError>
                      )}
                    </div>
                    <Button
                      className={classnames('button__type6 submit-btn', {
                        submitted: isSubmitted || isPatching,
                      })}
                      disabled={isSubmitted || isPatching}
                      onClick={onSubmit}
                    >
                      {isPatching ? (
                        <ul className="loading">
                          <li />
                          <li />
                          <li />
                          <li />
                        </ul>
                      ) : (
                        <Fragment>
                          {isSubmitted ? 'Email submited' : 'Submit email'}
                        </Fragment>
                      )}
                    </Button>
                  </Form>
                </>
              ) : (
                <div className="hero__subtitle bottom">Thank you for sharing your email with us! <br className="desktop-only"/>
                  We'll let you know about all Nerdify discounts and promos.</div>
              )}
            </div>
          </div>
        </Hero13>

        <Info6>
          <h2 className="h3 info6-title">
            Meanwhile, you might wanna know that:
          </h2>
          <p className="info6-p">
            All Nerds have successfully passed our strict examination process
            and proven their
            <br className="desktop-only" /> competence. That's why students rate
            Nerds 4.7/5 on average
          </p>
          <div className="info6-rate-img" />
        </Info6>

        <DemoCards
          titleTag={"h2"}
          title="Check what our clients say about their Nerdify experience"
          records={ pageCfg.demoCards ? demoCardsRecords[pageCfg.demoCards] : '' }
        />

        <Info5>
          <h2 className="h2 info5-title">
            We are only happy if you
            <br className="mobile-only" /> are satisfied
          </h2>
          <p className="info5-p">
            All our clients deserve to get the best Nerdify experience!
            <br />
            We have 100% money-back guarantee in case you think the Nerd we have
            found didn't meet your initial
            <br className="desktop-only" /> requirements.
            <br className="mobile-only" /> For more information please look at
            our{' '}
            <a href="/refunds" className="link">
              Refund Policy
            </a>
          </p>
        </Info5>

        <Faq2>
          <Holder>
            <h2 className={'h3'}>FAQ</h2>
            <Accordion>
              <AccordionItemHolder>
                <AccordionItem index={1}>
                  <AccordionHead>
                    <AccordionIcon icons={['plus', 'minus']} />
                    <h3 className={'h6'}>Who are the Nerds?</h3>
                  </AccordionHead>
                  <AccordionBody>
                    <p className={'p'}>
                      Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.
                    </p>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem index={2}>
                  <AccordionHead>
                    <AccordionIcon icons={['plus', 'minus']} />
                    <h3 className={'h6'}>What requests can a Nerd help me with?</h3>
                  </AccordionHead>
                  <AccordionBody>
                    <p className={'p'}>
                      Our Nerds can help you with any type of request except for those that are illegal or violate university and corporate policies. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.
                    </p>
                  </AccordionBody>
                </AccordionItem>
              </AccordionItemHolder>

              <AccordionItemHolder>
                <AccordionItem index={3}>
                  <AccordionHead>
                    <AccordionIcon icons={['plus', 'minus']} />
                    <h3 className={'h6'}>How do you find Nerds for your platform?</h3>
                  </AccordionHead>
                  <AccordionBody>
                    <p className={'p'}>
                      The platform algorithm picks the best-matching Nerd to be assigned to your request by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.
                    </p>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem index={4}>
                  <AccordionHead>
                    <AccordionIcon icons={['plus', 'minus']} />
                    <h3 className={'h6'}>How quickly can I get help from a Nerd?</h3>
                  </AccordionHead>
                  <AccordionBody>
                    <p className={'p'}>
                      Feel free to contact us at any time to get a Nerd assigned to your request! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work with you thoroughly.
                    </p>
                  </AccordionBody>
                </AccordionItem>
              </AccordionItemHolder>
            </Accordion>
          </Holder>
        </Faq2>

        <Footer2 />
      </div>
    </LocalesPage>
  );
};

export default ThankYou;
