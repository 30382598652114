import React from 'react';
/*
import trustpilot from './trustpilot';
import sitejabber from './sitejabber';
import facebook from './facebook';
import google from './google';
*/

import trustpilot from './trustpilot.json';
import sitejabber from './sitejabber.json';
import facebook from './facebook.json';
import google from './google.json';

import ReviewBlock from '../../../../themes/nerdify/components/ReviewBlock';

// const items = [ ...trustpilot, ...sitejabber, ...facebook, ...google ];

const items = {
  trustpilot: trustpilot,
  sitejabber: sitejabber,
  facebook: facebook,
  google: google,
};

const Reviews = () => {
  return <ReviewBlock items={items} />;
};

export default Reviews;
